<template>
    <div class="flex items-center justify-center pt-12">
        <div class="grid grid-cols-3 gap-4 w-fit">
            <div
                v-for="item in DataCard"
                :key="item.index"
                class="card-header pb-2 bg-cover bg-center xl:h-[470px] xl:w-[350px] 2xl:h-[700px] 2xl:w-[500px] text-white text-lg relative flex items-end xl:px-8 rounded-xl justify-center"
                :style="{ backgroundImage: `url(${item.image})`}"
                @mouseover="hoveredCard = item"
                @mouseleave="hoveredCard = null"
            >
                <div>
                    <transition name="fade">
                        <div v-if="hoveredCard === item" class="absolute inset-0 bg-white flex items-center justify-center rounded-xl text-center z-20">
                            <div class="bg-white rounded-xl w-full h-full ">
                                <div>
                                    <img :src="item.image_hover"  class="rounded-t-xl w-full"/>
                                    <div class="absolute top-0 left-0 flex gap-3 p-3">
                                        <svg width="25" height="25" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g id="Social Icons" clip-path="url(#clip0_1_576)">
                                            <path id="Vector" d="M33.3352 0H2.65781C1.18828 0 0 1.16016 0 2.59453V33.3984C0 34.8328 1.18828 36 2.65781 36H33.3352C34.8047 36 36 34.8328 36 33.4055V2.59453C36 1.16016 34.8047 0 33.3352 0ZM10.6805 30.6773H5.33672V13.493H10.6805V30.6773ZM8.00859 11.1516C6.29297 11.1516 4.90781 9.76641 4.90781 8.05781C4.90781 6.34922 6.29297 4.96406 8.00859 4.96406C9.71719 4.96406 11.1023 6.34922 11.1023 8.05781C11.1023 9.75937 9.71719 11.1516 8.00859 11.1516ZM30.6773 30.6773H25.3406V22.3242C25.3406 20.3344 25.3055 17.768 22.5633 17.768C19.7859 17.768 19.3641 19.9406 19.3641 22.1836V30.6773H14.0344V13.493H19.1531V15.8414H19.2234C19.9336 14.4914 21.6773 13.0641 24.2719 13.0641C29.6789 13.0641 30.6773 16.6219 30.6773 21.2484V30.6773Z" fill="white"/>
                                            </g>
                                            <defs>
                                            <clipPath id="clip0_1_576">
                                            <rect width="36" height="36" fill="white"/>
                                            </clipPath>
                                            </defs>
                                        </svg>

                                        <svg width="25" height="25" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g id="instagram">
                                            <path id="Vector" d="M26.25 9.75H26.265M10.5 3H25.5C29.6421 3 33 6.35786 33 10.5V25.5C33 29.6421 29.6421 33 25.5 33H10.5C6.35786 33 3 29.6421 3 25.5V10.5C3 6.35786 6.35786 3 10.5 3ZM23.9999 17.0551C24.185 18.3035 23.9718 19.5784 23.3906 20.6986C22.8093 21.8188 21.8896 22.7272 20.7624 23.2946C19.6351 23.862 18.3576 24.0595 17.1116 23.859C15.8656 23.6585 14.7146 23.0702 13.8222 22.1778C12.9298 21.2854 12.3415 20.1344 12.141 18.8884C11.9405 17.6424 12.138 16.3649 12.7054 15.2376C13.2728 14.1104 14.1812 13.1907 15.3014 12.6094C16.4216 12.0282 17.6966 11.815 18.9449 12.0001C20.2183 12.1889 21.3972 12.7823 22.3075 13.6925C23.2177 14.6028 23.8111 15.7817 23.9999 17.0551Z" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                                            </g>
                                        </svg>

                                        <svg width="25" height="25" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g id="Social Icons">
                                            <path id="Vector" d="M27.4894 2.85583H32.5496L21.4946 15.4911L34.5 32.6848H24.3169L16.3411 22.2569L7.21492 32.6848H2.15165L13.9762 19.17L1.5 2.85583H11.9417L19.1511 12.3873L27.4894 2.85583ZM25.7135 29.656H28.5174L10.4181 5.72553H7.40919L25.7135 29.656Z" fill="white"/>
                                            </g>
                                        </svg>
                                    </div>

                                    <div class="absolute left-0 top-0 mt-24 p-3">
                                        <div class="text-base font-medium text-left">Creator</div>
                                        <div class="xl:text-2xl font-bold font-comic">{{ item.creator }}</div>
                                    </div>
                                </div>

                                <div class="text-black text-justify">
                                    <div class="xl:text-xl font-semibold py-2 px-5">Summary</div>
                                    <div class="pt-2 px-5">{{ item.summary }}</div>
                                    <div class="pt-3 flex items-end justify-center gap-3">
                                        <button class="bg-second-green px-3 xl:text-base text-white hover:bg-green font-medium py-2 px-4 rounded-full">
                                          Read on App
                                        </button>
                                        <button class="bg-transparent px-3 xl:text-base hover:bg-green border border-second-green text-second-green font-medium py-2 px-4 rounded-full">
                                          Read on Kinddle
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                        <div v-else>
                            <div class="absolute inset-0 bg-gradient-to-t from-black opacity-75 via-transparent to-transparent rounded-xl"></div>
                            <img :src="item.image_title" class="pb-3 relative flex justify-center items-end z-10" />
                            <div class="relative xl:text-base font-medium text-white z-10 text-center xl:pb-2 2xl:pb-5">{{ item.title }}</div>
                        </div>
                    </transition>
                </div>
            </div>

            <div
                @mouseover="hoveredCard = item"
                @mouseleave="hoveredCard = null"
                class="card-header col-span-2"
            >
                <transition name="fade">
                    <div v-if="hoveredCard === item" class="p-10 h-full bg-second-green rounded-xl absolute inset-0 rounded-xl text-center z-20">
                        <div class="text-[32px] pb-5 font-comic font-bold text-center">Visi8 Comic App</div>
                        <div class="flex items-center justify-between">
                            <div>
                                <img class="h-[200px]" :src="thumb_phone" />
                            </div>
                            <div class="text-left">
                                <div class="flex items-center justify-center">
                                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g id="check-circle-2">
                                        <path id="Vector" d="M9.5 12.3486L11.5 14.3486L15.5 10.3486M22.5 12.3486C22.5 17.8715 18.0228 22.3486 12.5 22.3486C6.97715 22.3486 2.5 17.8715 2.5 12.3486C2.5 6.82579 6.97715 2.34863 12.5 2.34863C18.0228 2.34863 22.5 6.82579 22.5 12.3486Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </g>
                                    </svg>
                                    <div class="px-2 font-medium text-base">Read Visi8 Works in Indonesian</div>
                                </div>
                                <div class="flex items-center justify-center">
                                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g id="check-circle-2">
                                        <path id="Vector" d="M9.5 12.3486L11.5 14.3486L15.5 10.3486M22.5 12.3486C22.5 17.8715 18.0228 22.3486 12.5 22.3486C6.97715 22.3486 2.5 17.8715 2.5 12.3486C2.5 6.82579 6.97715 2.34863 12.5 2.34863C18.0228 2.34863 22.5 6.82579 22.5 12.3486Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </g>
                                    </svg>
                                    <div class="px-2 font-medium text-base">Latest news & events regarding Visi</div>
                                </div>
                                <div class="flex items-center justify-center">
                                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g id="check-circle-2">
                                        <path id="Vector" d="M9.5 12.3486L11.5 14.3486L15.5 10.3486M22.5 12.3486C22.5 17.8715 18.0228 22.3486 12.5 22.3486C6.97715 22.3486 2.5 17.8715 2.5 12.3486C2.5 6.82579 6.97715 2.34863 12.5 2.34863C18.0228 2.34863 22.5 6.82579 22.5 12.3486Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </g>
                                    </svg>
                                    <div class="px-2 font-medium text-base">Continue Reading Feature to read from where you left off</div>
                                </div>
                                <div class="flex items-center justify-center">
                                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g id="check-circle-2">
                                        <path id="Vector" d="M9.5 12.3486L11.5 14.3486L15.5 10.3486M22.5 12.3486C22.5 17.8715 18.0228 22.3486 12.5 22.3486C6.97715 22.3486 2.5 17.8715 2.5 12.3486C2.5 6.82579 6.97715 2.34863 12.5 2.34863C18.0228 2.34863 22.5 6.82579 22.5 12.3486Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </g>
                                    </svg>
                                    <div class="px-2 font-medium text-base">Buy Comics to add to your collection</div>
                                </div>
                                <div class="flex items-center justify-center">
                                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g id="check-circle-2">
                                        <path id="Vector" d="M9.5 12.3486L11.5 14.3486L15.5 10.3486M22.5 12.3486C22.5 17.8715 18.0228 22.3486 12.5 22.3486C6.97715 22.3486 2.5 17.8715 2.5 12.3486C2.5 6.82579 6.97715 2.34863 12.5 2.34863C18.0228 2.34863 22.5 6.82579 22.5 12.3486Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </g>
                                    </svg>
                                    <div class="px-2 font-medium text-base">Comment and like Visi8 works to support the authors</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="bg-second-green h-full rounded-xl">
                        <div class="p-10">
                            <div class="text-[32px] pb-5 font-comic font-bold text-center">Visi8 Comic App</div>
                            <div class="flex items-center justify-center">
                                <img class="h-80" :src="thumb_phone" />
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
            
            <!-- <transition
                name="fade"
                @mouseover="hoveredCard = item"
                @mouseleave="hoveredCard = null"
                class="card-header"
            >
                <div v-if="hoveredCard === item" class="col-span-2 bg-second-green rounded-xl">
                    <img class="h-80" :src="thumb_phone" />
                    <div>testing</div>
                </div>

                <div v-else class="col-span-2 bg-second-green rounded-xl">
                    <div class="p-10">
                        <div class="text-[32px] pb-5 font-comic font-bold text-center">Visi8 Comic App</div>
                        <div class="flex items-center justify-center">
                            <img class="h-80" :src="thumb_phone" />
                        </div>
                    </div>
                </div>
            </transition> -->
        </div>
    </div>
</template>

<script>
import thumb1 from '@/assets/thumb1.png'
import thumb2 from '@/assets/thumb2.png'
import thumb3 from '@/assets/thumb3.png'
import thumb4 from '@/assets/thumb4.png'
import thumb_title1 from '@/assets/thumb_title1.png'
import thumb_title2 from '@/assets/thumb_title2.png'
import thumb_title3 from '@/assets/thumb_title3.png'
import thumb_title4 from '@/assets/thumb_title4.png'
import card_hover1 from '@/assets/card_hover1.png'
import card_hover2 from '@/assets/card_hover2.png'
import card_hover3 from '@/assets/card_hover3.png'
import card_hover4 from '@/assets/card_hover4.png'
export default {
    name: 'CardFlip.vue',
    data () {
        return {
            thumb_phone: require('@/assets/ss_thumb.png'),
            hoveredCard: null,
            DataCard: [
                {creator:'Triandhika Anjani', title: 'Sci-fi, Fantasy, Completed', image_title: thumb_title1, image: thumb1, image_hover: card_hover1, summary: 'Follow the journey of Kara, as she navigates the meaning of the duties imposed to her, while unwittingly tapping her hidden potentials and finding her place among modern humans.'},
                {creator:'Kevin Vanhook', title: 'Sci-fi, Fantasy, Completed', image_title: thumb_title2, image: thumb2, image_hover: card_hover2, summary: 'Throughout time, children have been chosen – seemingly at random intervals – a human child and a demon from the Hantu spirit plane.'},
                {creator:'Andra Fembriarto', title: 'Sci-fi, Fantasy, Coming Soon', image_title: thumb_title3, image: thumb3, image_hover: card_hover3, summary: `Galeo is afraid to take the Ikiro Exam, which leads to an accident causing his family to be exiled. Galeo's younger sibling runs away from home, and Galeo must save them.`},
                {creator:'Kevin Vanhook', title: 'Sci-fi, Fantasy, Coming Soon', image_title: thumb_title4, image: thumb4, image_hover: card_hover4, summary: 'A prominent figure in the Indonesian criminal world was killed in an unusual manner, leading to a group of immortals who have been maintaining peace for centuries.'}
            ]
        }
    }
}
</script>

<style scoped>
.card-header {
  transition: transform 0.3s ease-in-out;
}

.card-header:hover {
  transform: scale(1.05);
}

/* .fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
} */
</style>
