<template>
    <div class="container relative w-full h-[700px] pb-16 overflow-hidden">
      <div class="slider-container relative h-full overflow-hidden">
        <div class="flex justify-center items-center w-screen h-full">
          <img
            v-for="(logo, index) in logos"
            :key="index"
            :src="logo"
            class="logo h-72 mt-80 absolute"
          />
        </div>
      </div>
      <button @click="prev" class="absolute left-4 top-1/2 transform -translate-y-1/2 bg-white text-black px-4 py-2">Prev</button>
      <button @click="next" class="absolute right-4 top-1/2 transform -translate-y-1/2 bg-white text-black px-4 py-2">Next</button>
    </div>
  </template>
  
  <script>
  import { gsap } from "gsap";
//   import thumb1 from '@/assets/thumb1.png'
//   import thumb2 from '@/assets/thumb2.png'
//   import thumb3 from '@/assets/thumb3.png'
  import doc1 from '@/assets/1.jpg'
  import doc2 from '@/assets/2.jpg'
  import doc3 from '@/assets/3.jpg'
  import doc4 from '@/assets/4.jpg'
  import doc5 from '@/assets/5.jpg'
  import doc6 from '@/assets/6.jpg'
  import doc7 from '@/assets/7.jpg'
  
  export default {
    name: "CircularCarousel",
    data() {
      return {
        logos: [
          doc1,
          doc2,
          doc3,
          doc4,
          doc5,
          doc6,
          doc7,
          doc1,
          doc5,
          doc6,
          doc7,
          doc1,
          doc7,
          doc1
        ],
        angle: 0,
        logosArray: [],
      };
    },
    mounted() {
      this.initializeLogos();
    },
    methods: {
      initializeLogos() {
        this.logosArray = gsap.utils.toArray(".logo");
  
        this.logosArray.forEach((logo, index) => {
          gsap.set(logo, {
            rotation: (360 / this.logosArray.length) * index,
            transformOrigin: "255px -1125px",
          });
        });
      },
      rotateLogos() {
        gsap.to(this.logosArray, {
          rotation: `+=${this.angle}`,
          ease: "power1.inOut",
          duration: 1,
        });
      },
      next() {
        this.angle = 360 / this.logosArray.length;
        this.rotateLogos();
      },
      prev() {
        this.angle = -360 / this.logosArray.length;
        this.rotateLogos();
      },
    },
  };
  </script>
  
  <style scoped>
  .slider-container {
    clip-path: inset(20% 0 0 0);
  }

  .polaroid::after {
    content: '';
    display: block;
    padding-bottom: 75%; /* Adjust this value to change the aspect ratio */
  }
  </style>