<template>
    <div class="bg-white h-fit">
      <div class="flex absolute inset-x-0 top-[120px] justify-center xl:inset-x-0 xl:top-48 xl:justify-center 2xl:top-48 items-center">
        <div style="box-shadow: 0.5px 8px grey;" class="font-comic shadow-black-2xl bantuan w-fit rounded-full text-white text-base xl:text-3xl text-center text-xl py-6 px-10 xl:py-10 xl:px-16 leading-relaxed">Kebijakan Privasi</div>
      </div>
      <div class="text-base text-justify leading-6 xl:px-24 px-5 pt-20 xl:pt-32">
        <div class="text-secondary">
          Di Visi8 Comics, yang dapat diinstal melalui Play 
          Store dan App Store, privasi pengunjung ,menjadi salah satu prioritas utama kami. 
          Dokumen Kebijakan Privasi ini berisi jenis informasi yang dikumpulkan dan dicatat 
          oleh Visi8 serta bagaimana kami menggunakannya.
        </div>

        <div class="text-secondary pt-5">
          Jika Anda memiliki pertanyaan tambahan atau memerlukan informasi lebih lanjut tentang 
          Kebijakan Privasi kami, jangan ragu untuk menghubungi kami melalui contact@visi8.com.
        </div>

        <div class="text-secondary py-5">
          Kebijakan Privasi ini berlaku hanya untuk aktivitas daring kami dan hanya untuk 
          pengunjung situs web serta aplikasi kami, sepanjang berkaitan dengan informasi 
          yang mereka bagikan dan/atau kumpulkan di Visi8. Kebijakan ini tidak berlaku untuk 
          informasi yang dibagikan dan/atau dikumpulkan secara luring atau melalui saluran 
          manapun selain situs web dan aplikasi kami.
        </div>

        <div>
          <div class="font-bold xl:text-xl">Persetujuan</div>

          <div class="text-secondary pt-2">
            Dengan menggunakan  aplikasi kami, Anda serta-merta memberikan persetujuan 
            terhadap Kebijakan Privasi kami dan menyetujui syarat-syaratnya.
          </div>
        </div>

        <div class="pt-5">
          <div class="font-bold xl:text-xl">Informasi yang kami kumpulkan</div>

          <div class="text-secondary pt-2">
            Informasi pribadi yang diminta kepada Anda, serta alasan mengapa Anda diminta 
            untuk memberikannya, akan disampaikan secara jelas pada saat kami meminta 
            informasi pribadi Anda.
          </div>

          <div class="text-secondary pt-5">
            Jika Anda memiliki pertanyaan tambahan atau memerlukan informasi lebih lanjut tentang 
            Kebijakan Privasi kami, jangan ragu untuk menghubungi kami melalui contact@visi8.com.
          </div>

          <div class="text-secondary pt-5">
            Saat Anda mendaftar untuk Akun, kami dapat meminta informasi kontak Anda, 
            termasuk hal-hal seperti nama dan alamat email.
          </div>
        </div>

        <div class="pt-5">
          <div class="font-bold xl:text-xl">Bagaimana kami menggunakan informasi Anda</div>

          <div class="text-secondary pt-2">
            Kami menggunakan informasi yang kami kumpulkan dengan berbagai cara, termasuk untuk:
          </div>

          <ul class="list-disc text-secondary xl:py-5 px-5">
            <li class="text-secondary pt-2">Menyediakan, mengoperasikan, dan memelihara aplikasi kami;</li>
            <li class="text-secondary pt-2">Meningkatkan, mempersonalisasi, dan memperluas aplikasi kami;</li>
            <li class="text-secondary pt-2">Memahami dan menganalisis cara Anda menggunakan aplikasi kami;</li>
            <li class="text-secondary pt-2">Mengembangkan produk, layanan, fitur, dan fungsionalitas baru;</li>
            <li class="text-secondary pt-2">Berkomunikasi dengan Anda, baik secara langsung maupun melalui salah satu mitra kami, termasuk untuk layanan pelanggan, memberikan pembaruan dan informasi lain yang berkaitan dengan aplikasi, serta untuk tujuan pemasaran dan promosi;</li>
            <li class="text-secondary pt-2">Mengirimkan email kepada Anda; dan/atau</li>
            <li class="text-secondary pt-2">Menemukan dan mencegah kecurangan</li>
          </ul>
        </div>

        <div class="pt-5">
          <div class="font-bold xl:text-xl">Berkas Log</div>

          <div class="text-secondary pt-2">
            Visi8 mengikuti prosedur standar penggunaan berkas log. Berkas-berkas ini 
            mencatat pengunjung saat mereka mengunjungi aplikasi. Semua perusahaan hosting 
            melakukan ini dan merupakan bagian dari analitik layanan hosting. Informasi yang 
            dikumpulkan oleh berkas log mencakup alamat protokol internet (IP), 
            jenis peramban, Penyedia Layanan Internet (ISP), tanggal dan waktu, halaman 
            acuan/keluar, lokasi scroll halaman, dan jumlah klik. Informasi tersebut tidak 
            terhubung dengan informasi yang dapat diidentifikasi secara pribadi. Tujuan dari 
            informasi ini adalah untuk menganalisis tren, mengelola situs, melacak pergerakan 
            pengguna di aplikasi, dan mengumpulkan informasi demografis.
          </div>
        </div>

        <div class="pt-5">
          <div class="font-bold xl:text-xl">Cookie dan Web Beacon</div>

          <div class="text-secondary pt-2">
            Seperti aplikasi lainnya, Visi8 menggunakan 'cookie'. Cookie ini digunakan untuk 
            menyimpan informasi, termasuk preferensi pengunjung dan halaman-halaman di 
            aplikasi yang diakses atau dikunjungi oleh pengunjung. Informasi ini digunakan 
            untuk mengoptimalkan pengalaman pengguna dengan menyesuaikan konten aplikasi kami 
            berdasarkan jenis peramban dan/atau informasi lain dari pengunjung.
          </div>
        </div>

        <div class="pt-5">
          <div class="font-bold xl:text-xl">Kebijakan Privasi Mitra Periklanan</div>

          <div class="text-secondary pt-2">
            Anda dapat mengonsultasikan daftar ini untuk menemukan Kebijakan Privasi untuk 
            masing-masing mitra periklanan Visi8.
          </div>

          <div class="text-secondary pt-5">
            Server iklan pihak ketiga atau jaringan periklanan menggunakan teknologi seperti 
            cookie, JavaScript, atau Web Beacon yang digunakan dalam iklan dan tautan mereka 
            yang muncul di Visi8, yang dikirim langsung ke peramban pengguna. Mereka secara 
            otomatis menerima alamat IP Anda ketika hal ini terjadi. Teknologi ini digunakan 
            untuk mengukur efektivitas kampanye periklanan mereka dan/atau untuk 
            mempersonalisasi konten periklanan yang Anda lihat di aplikasi yang Anda kunjungi.
          </div>

          <div class="text-secondary pt-5">
            Perhatikan bahwa Visi8 tidak memiliki akses atau kontrol atas cookie 
            yang digunakan oleh pengiklan pihak ketiga.
          </div>
        </div>

        <div class="pt-5">
          <div class="font-bold xl:text-xl">Third Party Privacy Policies</div>

          <div class="text-secondary pt-2">
            Kebijakan Privasi Visi8 tidak berlaku untuk pengiklan atau aplikasi lainnya. 
            Oleh karena itu, kami menyarankan Anda untuk mengonsultasikan Kebijakan Privasi 
            masing-masing server iklan pihak ketiga tersebut untuk informasi lebih rinci. 
            Hal ini mungkin mencakup praktik-praktik mereka dan instruksi mengenai cara 
            mengopt-out dari opsi tertentu.
          </div>

          <div class="text-secondary pt-5">
            Anda dapat memilih untuk menonaktifkan cookie melalui opsi peramban individu Anda. 
            Untuk informasi yang lebih rinci tentang pengelolaan cookie dengan peramban 
            aplikasi tertentu, Anda dapat menemukannya di aplikasi resmi peramban tersebut.
          </div>
        </div>

        <div class="pt-5">
          <div class="font-bold xl:text-xl">Hak Privasi UU PDP (Jangan Jual Informasi Pribadi Saya)</div>

          <div class="text-secondary pt-2">
            Berdasarkan UU Nomor 27 Tahun 2022 tentang Perlindungan Data Pribadi (UU PDP), 
            di antara hak-hak lainnya, setiap konsumen sebagai Subjek Data Pribadi di 
            Indonesia memiliki hak:
          </div>

          <div class="text-secondary pt-5">
            Meminta agar pelaku usaha sebagai Pengendali dan/atau Prosesor Data Pribadi 
            yang mengumpulkan data pribadi konsumen mengungkapkan kategori dan jenis data 
            pribadi yang dikumpulkan oleh bisnis tersebut.
          </div>

          <div class="text-secondary pt-5">
            Meminta agar pelaku usaha sebagai Pengendali dan/atau Prosesor 
            Data Pribadi menghapus data pribadi tentang konsumen yang dikumpulkan oleh bisnis tersebut.
          </div>

          <div class="text-secondary pt-5">
            Meminta agar pelaku usaha sebagai Pengendali dan/atau 
            Prosesor Data Pribadi untuk tidak menjual data pribadi konsumen tersebut.
          </div>

          <div class="text-secondary pt-5">
            Jika Anda membuat permintaan, kami memiliki waktu 3 x 24 
            (tiga kali dua puluh empat) jam untuk memberikan tanggapan kepada Anda. 
            Jika Anda ingin menggunakan salah satu dari hak-hak ini, silakan hubungi kami 
            (pilih "Penghapusan Data Pribadi" sebagai subjek).
          </div>
        </div>

        <div class="pt-5">
          <div class="font-bold xl:text-xl">Hak Perlindungan Data UU PDP dan GDPR</div>

          <div class="text-secondary pt-2">
            Kami ingin memastikan bahwa Anda sepenuhnya menyadari semua hak perlindungan data Anda. Setiap pengguna berhak atas hal-hal berikut:
          </div>

          <ul class="list-disc text-secondary xl:py-5 px-5">
            <li class="text-secondary pt-2">
              Hak akses: Anda berhak meminta salinan data pribadi Anda. Kami dapat mengenakan biaya kecil untuk layanan ini.
            </li>
            <li class="text-secondary pt-2">
              Hak koreksi: Anda berhak meminta kami untuk memperbaiki informasi yang menurut Anda tidak akurat. Anda juga berhak meminta kami untuk melengkapi informasi yang menurut Anda tidak lengkap.
            </li>
            <li class="text-secondary pt-2">
              Hak penghapusan: Anda berhak meminta kami menghapus data pribadi Anda, dalam beberapa kondisi tertentu.
            </li>
            <li class="text-secondary pt-2">
              Hak pembatasan pemrosesan: Anda berhak meminta kami membatasi pemrosesan data pribadi Anda, dalam beberapa kondisi tertentu.
            </li>
            <li class="text-secondary pt-2">
              Hak penolakan pemrosesan: Anda berhak menolak pemrosesan data pribadi Anda oleh kami, dalam beberapa kondisi tertentu.
            </li>
            <li class="text-secondary pt-2">
              Hak portabilitas data: Anda berhak meminta kami mentransfer data yang telah kami kumpulkan ke organisasi lain, atau langsung kepada Anda, dalam beberapa kondisi tertentu.
            </li>
          </ul>

          <div class="text-secondary pt-5">
            Jika Anda mengajukan permintaan, kami memiliki waktu 3 x 24 
            (tiga kali dua puluh empat) jam untuk memberikan tanggapan kepada Anda. 
            Jika Anda ingin menggunakan salah satu hak ini, silakan hubungi kami.
          </div>
        </div>

        <div class="pt-5">
          <div class="font-bold xl:text-xl">Informasi tentang Anak-anak</div>

          <div class="text-secondary pt-2">
            Bagian lain dari prioritas kami adalah melindungi anak-anak saat menggunakan
            internet. Kami mendorong para orang tua dan wali untuk mengawasi, berpartisipasi, 
            dan/atau memantau serta membimbing aktivitas online anak-anak mereka.
          </div>

          <div class="text-secondary pt-5 pb-10">
            Visi8 tidak sengaja mengumpulkan Informasi Identifikasi Pribadi dari anak-anak di 
            bawah usia 13 tahun. Jika Anda berpikir bahwa anak Anda telah memberikan jenis informasi 
            ini di aplikasi atau aplikasi kami, kami sangat mendorong Anda untuk segera menghubungi 
            kami, dan kami akan melakukan upaya terbaik kami untuk segera menghapus informasi 
            tersebut dari catatan kami.
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import siluet from '@/assets/siluet.png'
  
  export default {
    name: 'PrivacyPolicy',
    data () {
      return {
        background: siluet,
      }
    },
    components: {
      // HelloWorld
    }
  }
  </script>
  
  <style scoped>
  
  .bantuan {
    background-color: #524652;
  }
  
  </style>
  