<template>
    <div class="py-5 bg-footer w-full p-5 xl:px-24 xl:justify-start text-white xl:items-center gap-[833px] inline-flex">
        <div class="xl:justify-start xl:items-center gap-4 xl:flex">
            <img class="h-4 xl:h-full" :src="logo" />
            <div class="justify-start items-start flex">
              <div class="pt-1.5 xl:pt-0.5">
                <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="14" height="14" fill="white"><path d="m12.038.019C5.421.019.038,5.402.038,12.019s5.383,12,12,12,12-5.383,12-12S18.655.019,12.038.019Zm0,22c-5.514,0-10-4.486-10-10S6.524,2.019,12.038,2.019s10,4.486,10,10-4.486,10-10,10Zm-3.25-11.159v2.318c0,1.733,1.339,3.091,3.049,3.091h.454c.862,0,1.782-.371,2.238-.901.362-.419.992-.466,1.41-.107.419.36.467.992.107,1.41-.834.971-2.309,1.599-3.756,1.599h-.454c-2.784,0-5.049-2.284-5.049-5.091v-2.318c0-2.807,2.265-5.091,5.049-5.091h.454c1.485,0,2.904.71,3.797,1.9.332.442.242,1.069-.2,1.4-.442.332-1.069.241-1.399-.2-.517-.689-1.338-1.1-2.197-1.1h-.454c-1.71,0-3.049,1.358-3.049,3.091Z"/></svg>
              </div>
              <div class="text-white text-start text-sm xl:text-base pt-1 xl:pt-0 pl-1">2024 Visi8 - PT. VISI DELAPAN SEMESTA. All Rights Reserved.</div>
            </div>
        </div>
    </div>

    <!-- <template>
      <div class="py-5 bg-footer w-full px-24 justify-start text-white items-center gap-[833px] inline-flex">
          <div class="justify-start items-center gap-4 flex">
              <img class="" :src="logo" />
              <div class="justify-start items-center gap-1 pl-4 flex">
                  <img class="w-6" :src="c" />
                  <div class="text-white text-base pl-2 pt-1">2024 Visi8 - PT. VISI DELAPAN SEMESTA. All Rights Reserved.</div>
              </div>
          </div>
      </div>
  </template> -->
</template>

<script>
import logo from '@/assets/Logo_2.png'
import c from '@/assets/Vector.png'

export default {
  name: 'FooTer',
  data () {
    return {
      logo: logo,
      c: c
    }
  },
  components: {
    // HelloWorld
  }
}
</script>