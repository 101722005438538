<template>
    <div class="flex bg-green justify-center items-center w-full px-5 py-2">
        <div class="text-xl font-bold">NEWS & EVENTS</div>
        <div class="relative overflow-hidden h-10 w-[950px] mx-5 bg-gray-800">
            <div class="absolute animate-marquee pt-2 flex justify-center items-center">
                <span class="text-white font-bold text-xl">ENGLISH VERSION AVAILABLE ON AMAZON </span>
                <div class="w-1 h-1 bg-white rounded-full mx-4"></div>
                <span class="text-white font-bold text-xl">VISI8 GOES TO INACON 2024</span>
                <div class="w-1 h-1 bg-white rounded-full mx-4"></div>
                <span class="text-white font-bold text-xl">OUT OF SIGHT</span>
                <!-- Add more text spans as needed -->
            </div>
        </div>
        <div class="text-xl font-bold">SEE ALL</div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'MovingFooter',
  };
  </script>
  
  <style scoped>
  @keyframes marquee {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  
  .animate-marquee {
    animation: marquee 8s linear infinite; /* Adjust the duration as needed */
  }
  </style>
  