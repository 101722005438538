<template>
    <div class="bg-white h-fit">
      <div class="flex absolute inset-x-0 top-[120px] justify-center xl:inset-x-0 xl:top-48 xl:justify-center 2xl:top-48 items-center">
        <div style="box-shadow: 0.5px 8px grey;" class="font-comic shadow-black-2xl bantuan w-fit rounded-full text-white text-base xl:text-3xl text-center text-xl py-6 px-10 xl:py-10 xl:px-16 leading-relaxed">Hapus Akun</div>
      </div>
      <div class="text-base text-justify leading-6 xl:px-24 px-5 pt-20 xl:pt-32">
        <div class="text-secondary text-base">
            <div>Kami sangat menghargai Anda sebagai pengguna aplikasi komik kami dan berharap Anda tetap menjadi bagian dari komunitas kami. Menghapus akun berarti Anda akan kehilangan akses ke semua komik favorit, histori bacaan, serta keuntungan lainnya yang Anda nikmati sebagai pengguna kami. Sebelum Anda mengambil langkah ini, mungkin Anda ingin mempertimbangkan beberapa alternatif berikut:</div>
            <div class="py-5"><a class="font-bold xl:pt-5">Masalah Teknis:</a> Jika Anda mengalami masalah teknis, kami dapat membantu! Banyak masalah dapat diatasi dengan panduan singkat atau pembaruan aplikasi. Hubungi tim dukungan kami untuk bantuan cepat.</div>
            <div class="pb-5"><a class="font-bold xl:pt-5">Peningkatan Pengalaman:</a> Jika ada fitur yang Anda rasa kurang atau ide untuk peningkatan, beri tahu kami! Umpan balik Anda sangat berharga untuk pengembangan aplikasi yang lebih baik.</div>
            <div class="pb-5">Namun, jika Anda tetap ingin melanjutkan penghapusan akun, berikut adalah langkah-langkahnya:</div>
        </div>
        <div class="font-bold text-xl">Langkah-Langkah Menghapus Akun</div>
        <ul class="px-5 list-decimal">
          <div v-for="i in list" :key="i.index">
            <li class="font-bold text-sm pt-3 xl:pt-3 xl:text-base">{{ i.step }}</li>
            <div class="text-sm text-secondary pt-1">{{ i.detail }}</div>
          </div>
        </ul>
  
        <div>
          <div class="font-bold xl:text-xl pt-5">Hubungi Kami</div>
          <div class="text-secondary pt-3 xl:pt-2">
            <div>Jika Anda tidak menemukan jawaban yang Anda cari di atas, Anda bisa menghubungi tim dukungan pelanggan kami.</div>
            <div class="xl:pt-5 pt-3">Email: <a href="mailto:support@visi8.com?subject=Isu/Masalah%20yang%20Kamu%20Alami%20&body=Tolong%20jelaskan%20lebih%20detail%20mengenai%20 isu/masalah%20tersebut.." class="underline text-green">support@visi8.com</a></div>
            <div class="xl:pt-5 pt-3 pb-10">Kami selalu siap membantu Anda!</div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import siluet from '@/assets/siluet.png'
  
  export default {
    name: 'DeleteAccount',
    data () {
      return {
        background: siluet,
        list: [
          {step: 'Masuk ke Akun Anda', detail: 'Buka aplikasi komik dan masuk ke akun Anda.'},
          {step: 'Akses Profile Anda', detail: 'Klik ikon profil Anda di sudut kanan atas layar.'},
          {step: 'Pilih "Hapus Akun"', detail: 'Di halaman profil Anda akan menemukan opsi "Hapus Akun". Klik opsi ini untuk melanjutkan.'},
          {step: 'Konfirmasi Penghapusan', detail: 'Kami akan meminta Anda untuk mengkonfirmasi penghapusan akun Anda. Bacalah informasi yang disediakan dan jika Anda yakin ingin melanjutkan, klik "Ya, Hapus Akun".'},
          {step: 'Selesai', detail: 'Setelah konfirmasi, akun Anda akan dihapus dari sistem kami. Anda akan menerima email konfirmasi penghapusan akun.'},
        ],
      }
    },
    components: {
      // HelloWorld
    }
  }
  </script>
  
  <style scoped>
  
  .bantuan {
    background-color: #524652;
  }
  
  </style>
  