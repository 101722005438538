<template>
    <header class="text-white absolute top-0 left-0 w-full z-50">
      <nav class="font-comic xl:text-2xl font-bold xl:gap-[125px] xl:py-12 xl:px-24 px-4 py-4 flex w-full justify-between items-center">
        <a href="#section1" @click.prevent="scrollToSection('section1')"><img class="h-7 xl:px-8 xl:py-3 xl:h-14" :src="logo" /></a>
        <a href="#section2" @click.prevent="scrollToSection('section2')" class="xl:px-8 xl:py-3">Works</a>
        <a href="#section3" @click.prevent="scrollToSection('section3')" class="xl:px-8 xl:py-3">Who We Are</a>
        <a href="#section4" @click.prevent="scrollToSection('section4')" class="xl:px-8 xl:py-3">Contact</a>
      </nav>
    </header>
  </template>
  
  <script>
  import logo from '@/assets/logo.png'
  export default {
    name: "NavBarDashboard",
    data() {
      return {
        scrolled: false,
        logo: logo
      };
    },
    // mounted() {
    //   window.addEventListener('scroll', this.handleScroll);
    // },
    // beforeUnmount() {
    //   window.removeEventListener('scroll', this.handleScroll);
    // },
    methods: {
      handleScroll() {
        this.scrolled = window.scrollY > 50;
      },
      scrollToSection(sectionId) {
        const element = document.getElementById(sectionId);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      },
    },
  };
  </script>
  
  <style scoped>
  header {
    background-color: transparent;
  }
  </style>
  