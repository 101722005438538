<template>
    <div class="text-white">
        <div class="h-full ">
            <div class="h-screen snap-start bg-red-500 flex items-center justify-center">
                <div id="section1" class="background bg-cover bg-center">
                    <navbar-dashboard />
                    <div class="delay animate__animated animate__fadeIn">
                        <div class="xl:py-10 content flex flex-col justify-center items-center">
                        <!-- Your other components go here -->
                            <div class="pt-16">
                                <!-- <div class="justify-center items-center flex">
                                    <img class="xl:h-24 xl:w-72" :src="logo" />
                                    <svg class="w-[89.60px] h-8 xl:w-[134.40px] xl:h-12" viewBox="0 0 90 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M89.821 31.2628H80.8474L83.3243 1.40402H75.1899L77.748 31.2628H59.5844C60.1257 31.1812 60.6536 31.0587 61.1679 30.909C62.2507 30.5824 63.1575 30.0652 63.8884 29.3711C64.6193 28.6771 65.2283 27.6836 65.7291 26.3907C66.2299 25.0978 66.4871 23.7233 66.4871 22.2399C66.4871 20.702 66.2029 19.3003 65.6344 18.0346C65.0659 16.7689 64.1862 15.7482 63.0086 14.9725C61.9665 13.9926 60.3288 13.04 59.1512 12.4003C58.4204 12.0057 57.6489 11.7335 56.8774 11.4749C54.9825 10.8489 54.9825 8.95718 54.9825 8.95718C54.9825 7.47377 56.1871 6.27615 57.6624 6.27615C59.1377 6.27615 60.3288 7.48738 60.3288 8.97079L65.8239 8.73944C65.8239 6.45307 65.1336 4.54777 63.7531 3.03714C62.3725 1.52651 60.2205 0.764387 57.3241 0.750778C55.1314 0.750778 53.3719 1.34959 52.032 2.56081C50.3266 4.13949 49.4604 6.16728 49.4604 8.64417C49.4604 10.1548 49.7446 11.4885 50.3401 12.6453C50.7732 13.4891 51.4635 14.2648 52.4109 14.9589L58.8535 19.6405C58.8535 19.6405 60.7754 20.8381 60.1934 22.7298C59.8415 23.9002 58.8129 24.7304 57.5948 24.7168C56.133 24.7031 54.9555 23.5055 54.969 22.0221L48.7701 22.1718C48.7701 23.9546 49.0814 25.5605 49.704 27.0031C50.3266 28.4457 51.247 29.5481 52.4651 30.283C53.2636 30.7729 54.3058 31.0995 55.5645 31.2764H40.5004L37.9423 1.40402H34.8564L32.3795 31.2628H16.6116L25.179 1.40402H19.305L14.5407 18.4837L10.2096 1.40402H3.8889L13.0384 31.2628H0.802979" fill="#45AD4C"/>
                                    </svg>
                                
                                    <svg class="w-[25.79px] h-8 xl:w-[38.68px] xl:h-12" viewBox="0 0 40 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M26.5834 8.69742C25.0404 8.05778 23.6328 7.98974 22.3335 8.53411C21.0477 9.06487 20.0867 10.0856 19.4506 11.5962C18.7874 13.2021 18.7197 14.6447 19.2476 15.9103C19.7754 17.1896 20.7905 18.1423 22.2929 18.7683C23.8088 19.4079 25.2164 19.4624 26.5022 18.9452C27.788 18.4281 28.7625 17.3529 29.4392 15.7606C30.0618 14.25 30.116 12.8619 29.5881 11.569C29.0603 10.2761 28.0587 9.32345 26.5834 8.69742Z" fill="#45AD4C"/>
                                        <path d="M19.7342 25.2459C17.7852 24.4294 16.0392 24.4838 14.5233 25.4365C13.0074 26.3891 11.9111 27.6548 11.2479 29.2335C10.3275 31.4518 10.1786 33.4115 10.7877 35.1263C11.3968 36.8411 12.5472 38.0523 14.2391 38.76C15.8903 39.454 17.5009 39.4268 19.0845 38.6919C20.6681 37.957 21.9133 36.46 22.8607 34.2145C23.6728 32.2547 23.7811 30.4447 23.1855 28.7843C22.5629 27.1376 21.426 25.9536 19.7342 25.2459Z" fill="#45AD4C"/>
                                        <path d="M14.2409 0.000335693H7.39231H14.2409ZM5.83581 0.000335693H0.516663V48.0003H39.199V0.000335693H35.3009M37.1823 18.3593C36.3702 20.3054 35.1521 21.8297 33.5144 22.9184C31.8767 24.0071 29.9818 24.5787 27.8298 24.6196C29.7517 26.5249 30.9428 28.6343 31.4029 30.9343C31.8631 33.2342 31.606 35.5614 30.6315 37.9022C29.0208 41.7809 26.4763 44.4075 23.0114 45.7956C19.5465 47.1838 15.7703 47.0205 11.6964 45.3057C7.90663 43.7134 5.15908 41.3862 3.46723 38.3105C1.47763 34.6904 1.32875 30.8254 3.04766 26.7018C3.99509 24.429 5.41624 22.5782 7.32464 21.1492C9.23303 19.7202 11.6016 18.9989 14.4439 18.9853C12.7385 17.2433 11.737 15.3925 11.4392 13.4735C11.1414 11.541 11.3715 9.66294 12.1295 7.82568C13.4288 4.69554 15.5944 2.57249 18.6126 1.44292C21.6309 0.31335 25.1499 0.585536 29.1562 2.27309C33.1218 3.94703 35.7747 6.26061 37.1146 9.21383C38.4545 12.1807 38.4816 15.2291 37.1823 18.3593Z" fill="#45AD4C"/>
                                    </svg>
                                </div> -->
                                
                                    <div class="py-8 xl:py-12 px-5 text-center">
                                        <div style="text-shadow: 10px 20px 1px black;"  class="text-white font-comic text-6xl xl:text-8xl xl:w-[660px]">Be A Part Of Our Story</div>
                                    <div class="xl:pt-10 text-white text-sm font-bold pt-8 xl:text-2xl">Explore Our Worlds, embrace the adventure</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <moving-footer class="absolute bottom-0 inset-x-0"/>
               </div>
            </div>

            <section id="section2" class="bg-rose h-fit snap-start bg-green-500">
                <div class="flex items-start justify-center">
                    <div class="flex items-center justify-center xl:pt-10">
                        <div style="text-shadow: 5px 5px 1px black; box-shadow: 0.5px 8px grey;" class="text-center flex items-center justify-center bg-green w-72 font-comic text-white rounded-t-full xl:px-14 xl:py-6 xl:text-[42px]">Works</div>
                    </div>
                </div>
                <card-flip class="px-10" />
            </section>

            <div id="section3" class="bg-rose h-screen snap-start bg-green-500">
                <div class="text-black text-2xl flex items-center justify-center font-bold font-comic xl:text-[54px] xl:pt-16">Who We Are</div>
                <photo-slider class="mt-8"/>
                <div class="text-center text-black mt-8">
                    <div class=" py-2.5 h-screen">
                        <div class="text-2xl font-medium">
                            Visi8 is a media and entertainment intellectual 
                            property (IP) development company. 
                        </div>
                        <div id="section4" class="snap-start h-screen text-xl font-medium pt-5">
                            <div class="px-28 pt-8">
                                <a class="text-2xl font-bold">Our Passion</a> lies in <a class="text-2xl font-bold">transforming</a>
                                original and licensed IP into <a class="text-2xl font-bold">multiple forms of art and experience.</a>
                                From comic books to 2D and 3D animated films, we <a class="text-2xl font-bold">strive to create worlds to entertain and inspire. </a>
                            </div>
                            <div class="relative  bg-rose h-full">
                                <div class="text-white text-2xl">
                                    <img class="absolute inset-x-0 bottom-0" :src="footer" />
                                    <div class="flex justify-center items-center absolute inset-x-0 bottom-0 z-10">
                                        <div>
                                            <div class="flex justify-center items-center">
                                                <img class="h-24" :src="logo_white" />
                                            </div>
                                            <div class="text-base text-center font-medium">
                                                <div class="pt-5">                                                <div>Didn't find what you are looking for?,</div>
                                                    <div>No worries! you can contact our customer support team at <a class="font-bold">support@visi8.com</a></div>
                                                    <div class="pt-3 pl-2"><a class="font-base font-bold">Studio Hours:</a>Mon-Fri 11am - 5pm (GMT7+)</div>
                                                </div>
                                                <div class="pt-5 pb-12 flex items-center justify-center gap-14">
                                                    <d>Works</d>
                                                    <a>Who We Are</a>
                                                    <a>Contact</a>
                                                    <a href="/help-support">Help and Support</a>
                                                    <a>Term of Use</a>
                                                    <a>News & Events</a>
                                                </div>
                                                <div class="pb-3 flex items-center justify-between">
                                                    <div class="gap-1 flex items-center justify-between">
                                                        <div>
                                                            <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="14" height="14" fill="white"><path d="m12.038.019C5.421.019.038,5.402.038,12.019s5.383,12,12,12,12-5.383,12-12S18.655.019,12.038.019Zm0,22c-5.514,0-10-4.486-10-10S6.524,2.019,12.038,2.019s10,4.486,10,10-4.486,10-10,10Zm-3.25-11.159v2.318c0,1.733,1.339,3.091,3.049,3.091h.454c.862,0,1.782-.371,2.238-.901.362-.419.992-.466,1.41-.107.419.36.467.992.107,1.41-.834.971-2.309,1.599-3.756,1.599h-.454c-2.784,0-5.049-2.284-5.049-5.091v-2.318c0-2.807,2.265-5.091,5.049-5.091h.454c1.485,0,2.904.71,3.797,1.9.332.442.242,1.069-.2,1.4-.442.332-1.069.241-1.399-.2-.517-.689-1.338-1.1-2.197-1.1h-.454c-1.71,0-3.049,1.358-3.049,3.091Z"/></svg>
                                                        </div>
                                                        <div >2024 Visi8 - PT. VISI DELAPAN SEMESTA. All Rights Reserved.</div>
                                                    </div>
                                                    <div>
                                                        <div class="flex justify-center items-center gap-8">
                                                            <div class="relative flex justify-center items-center ">
                                                              <a href="https://www.linkedin.com/company/visi-8-studio/">
                                                                <svg width="24" height="24" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                  <g id="Social Icons" clip-path="url(#clip0_1_576)">
                                                                  <path id="Vector" d="M33.3352 0H2.65781C1.18828 0 0 1.16016 0 2.59453V33.3984C0 34.8328 1.18828 36 2.65781 36H33.3352C34.8047 36 36 34.8328 36 33.4055V2.59453C36 1.16016 34.8047 0 33.3352 0ZM10.6805 30.6773H5.33672V13.493H10.6805V30.6773ZM8.00859 11.1516C6.29297 11.1516 4.90781 9.76641 4.90781 8.05781C4.90781 6.34922 6.29297 4.96406 8.00859 4.96406C9.71719 4.96406 11.1023 6.34922 11.1023 8.05781C11.1023 9.75937 9.71719 11.1516 8.00859 11.1516ZM30.6773 30.6773H25.3406V22.3242C25.3406 20.3344 25.3055 17.768 22.5633 17.768C19.7859 17.768 19.3641 19.9406 19.3641 22.1836V30.6773H14.0344V13.493H19.1531V15.8414H19.2234C19.9336 14.4914 21.6773 13.0641 24.2719 13.0641C29.6789 13.0641 30.6773 16.6219 30.6773 21.2484V30.6773Z" fill="white"/>
                                                                  </g>
                                                                  <defs>
                                                                  <clipPath id="clip0_1_576">
                                                                  <rect width="36" height="36" fill="white"/>
                                                                  </clipPath>
                                                                  </defs>
                                                                </svg>
                                                              </a>            
                                                            </div>
                                                            <div class="relative flex justify-center items-center ">
                                                              <a href="https://www.instagram.com/visi8.studio/">
                                                                <svg width="24" height="24" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                  <g id="instagram">
                                                                  <path id="Vector" d="M26.25 9.75H26.265M10.5 3H25.5C29.6421 3 33 6.35786 33 10.5V25.5C33 29.6421 29.6421 33 25.5 33H10.5C6.35786 33 3 29.6421 3 25.5V10.5C3 6.35786 6.35786 3 10.5 3ZM23.9999 17.0551C24.185 18.3035 23.9718 19.5784 23.3906 20.6986C22.8093 21.8188 21.8896 22.7272 20.7624 23.2946C19.6351 23.862 18.3576 24.0595 17.1116 23.859C15.8656 23.6585 14.7146 23.0702 13.8222 22.1778C12.9298 21.2854 12.3415 20.1344 12.141 18.8884C11.9405 17.6424 12.138 16.3649 12.7054 15.2376C13.2728 14.1104 14.1812 13.1907 15.3014 12.6094C16.4216 12.0282 17.6966 11.815 18.9449 12.0001C20.2183 12.1889 21.3972 12.7823 22.3075 13.6925C23.2177 14.6028 23.8111 15.7817 23.9999 17.0551Z" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                                                                  </g>
                                                                </svg>
                                                              </a>
                                                            </div>
                                                            <div class="relative flex justify-center items-center ">
                                                              <a href="https://twitter.com/visi8studio">
                                                                <svg width="24" height="24" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                  <g id="Social Icons">
                                                                  <path id="Vector" d="M27.4894 2.85583H32.5496L21.4946 15.4911L34.5 32.6848H24.3169L16.3411 22.2569L7.21492 32.6848H2.15165L13.9762 19.17L1.5 2.85583H11.9417L19.1511 12.3873L27.4894 2.85583ZM25.7135 29.656H28.5174L10.4181 5.72553H7.40919L25.7135 29.656Z" fill="white"/>
                                                                  </g>
                                                                </svg>
                                                              </a>            
                                                            </div>
                                                            <div class="relative flex justify-center items-center ">
                                                              <a href="https://www.youtube.com/channel/UC7dPC03EMgVBexcvPC-zqCw">
                                                                <svg width="26" height="26" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                  <g id="YouTube">
                                                                  <path id="Shape" fill-rule="evenodd" clip-rule="evenodd" d="M18 9C14.1761 9 10.9653 9.1461 8.58742 9.30725C7.3399 9.3918 6.33725 10.3758 6.25033 11.7124C6.11599 13.7785 6 16.118 6 18C6 19.882 6.11599 22.2215 6.25033 24.2876C6.33725 25.6242 7.3399 26.6082 8.58742 26.6927C10.9653 26.8539 14.1761 27 18 27C21.8239 27 25.0347 26.8539 27.4126 26.6927C28.6601 26.6082 29.6628 25.6242 29.7497 24.2876C29.884 22.2215 30 19.882 30 18C30 16.118 29.884 13.7785 29.7497 11.7124C29.6628 10.3758 28.6601 9.3918 27.4126 9.30725C25.0347 9.1461 21.8239 9 18 9ZM8.38457 6.31412C5.59273 6.50332 3.43822 8.72541 3.25666 11.5177C3.12108 13.6028 3 16.0193 3 18C3 19.9807 3.12108 22.3972 3.25666 24.4823C3.43822 27.2746 5.59273 29.4967 8.38457 29.6859C10.8215 29.851 14.1004 30 18 30C21.8996 30 25.1785 29.851 27.6154 29.6859C30.4073 29.4967 32.5618 27.2746 32.7433 24.4823C32.8789 22.3972 33 19.9807 33 18C33 16.0193 32.8789 13.6028 32.7433 11.5177C32.5618 8.7254 30.4073 6.50332 27.6154 6.31412C25.1785 6.14897 21.8996 6 18 6C14.1004 6 10.8215 6.14897 8.38457 6.31412Z" fill="white"/>
                                                                  <path id="Shape_2" d="M15 15.0023C15 13.8064 16.331 13.0913 17.3281 13.7516L21.8182 16.7245C22.732 17.3296 22.7319 18.671 21.8181 19.276L17.328 22.2486C16.3309 22.9088 15 22.1937 15 20.9979V15.0023Z" fill="white"/>
                                                                  </g>
                                                                </svg>
                                                              </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import gif from '@/assets/output.gif'
// import MenuStacking from '@/components/MenuStacking.vue'
import CardFlip from '@/components/CardFlip.vue'
import PhotoSlider from '@/components/PhotoSlider.vue'
import NavbarDashboard from '@/components/NavBarDashboard.vue'
import MovingFooter from '@/components/MovingFooter.vue'
import footer from '@/assets/footer_clear.png'
import logo from '@/assets/logo_white.png'
export default {
    name: 'DashBoard',
    data () {
        return {
            loop: gif,
            footer: footer,
            logo_white: logo
        }
    },
    components: {
        // MenuStacking,
        PhotoSlider,
        CardFlip,
        MovingFooter,
        NavbarDashboard
    }
}
</script>

<style scoped>
.background {
  /* Set the background image */
  background-image: url('@/assets/output.gif');
  /* Set the size of the container */
  width: 100vw;
  height: 100vh;
  position: relative; /* Make sure it's relative for absolute positioning of other components */
}

.footer-background {
    background-image: url('@/assets/footer_clear.png');
}

.content {
  /* Position the content on top of the background */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>