<template>
  <div class="bg-white h-fit">
    <div class="flex absolute inset-x-0 top-[120px] justify-center xl:inset-x-0 xl:top-48 xl:justify-center 2xl:top-48 items-center">
      <div style="box-shadow: 0.5px 8px grey;" class="font-comic shadow-black-2xl bantuan w-fit rounded-full text-white text-base xl:text-3xl text-center text-xl py-6 px-10 xl:py-10 xl:px-16 leading-relaxed">Bantuan & Dukungan</div>
    </div>
    <div class="text-base text-justify leading-6 xl:px-24 px-5 pt-20 xl:pt-32">
      <div class="text-secondary">Selamat datang di halaman bantuan penggunaan aplikasi komik Visi8! Kami akan bantu kamu dapat pengalaman terbaik dalam membaca komik kami. Berikut adalah beberapa pertanyaan yang sering diajukan dan solusi untuk masalah yang umum. Jika kamu perlu bantuan lebih lanjut, jangan ragu untuk hubungi kami ya!</div>
      <div>
        <div class="font-bold text-xl mt-5">Pertanyaan Umum</div>
      
        <ul class="px-5 pb-5 list-decimal">
          <div class="py-2" v-for="i in list" :key="i.index">
            <li class="font-bold xl:text-base">{{ i.pertanyaan }}</li>
            <div class="text-secondary">{{ i.jawaban }}</div>
          </div>
        </ul>
      </div>

      <div>
        <div class="font-bold xl:text-xl">Hubungi Kami</div>
        <div class="text-secondary pt-3 xl:pt-2">
          <div>Jika Anda tidak menemukan jawaban yang Anda cari di atas, Anda bisa menghubungi tim dukungan pelanggan kami.</div>
          <div class="xl:pt-5 pt-3">Email: <a href="mailto:support@visi8.com?subject=Isu/Masalah%20yang%20Kamu%20Alami%20&body=Tolong%20jelaskan%20lebih%20detail%20mengenai%20 isu/masalah%20tersebut.." class="underline text-green">support@visi8.com</a></div>
          <div class="xl:pt-5 pt-3 pb-10">Kami selalu siap membantu Anda!</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import siluet from '@/assets/siluet.png'

export default {
  name: 'HelpSupport',
  data () {
    return {
      background: siluet,
      list: [
        {pertanyaan: 'Bagaimana cara membuat akun?', jawaban: 'Untuk membuat akun, klik tombol "Buat Akun" di halaman utama apilkasi. Isi formular dengan informasi yang diperlukan seperti email dan kata sandi. Selamat!, kamu sudah berhasil mempunyai akun.'},
        {pertanyaan: 'Bagaimana cara membaca komik?', jawaban: 'Pada halaman beranda, tap komik yang ingin kamu baca lalu akan terbuka halaman detail komik. Pada halaman tersebut, tap pada tab "episode" dan akan muncul halaman untuk membaca komik dengan cara scroll ke bawah sampai selesai. Untuk lanjut ke episode berikutnya, kamu bisa tap tombol di pojok kanan Bawah yang berwarna hijau. Ada beberapa episode komik yang kamu harus beli sebelum kamu bisa membacanya.'},
        {pertanyaan: 'Apa yang harus dilakukan jika saya lupa kata sandi?', jawaban: 'Klik "Lupa Kata Sandi" di halaman masuk. Masukkan alamat email Akun kamu, dan kami akan intruksikan untuk mereset kata sandi.'},
        {pertanyaan: 'Aplikasi tidak bisa dibuka, apa yang harus saya lakukan?', jawaban: 'Coba tutup dan buka kembali aplikasi. Jika masalah berlanjut, restart perangkat Anda. Pastikan juga aplikasi dan sistem operasi Anda dalam versi terbaru.'},
        {pertanyaan: 'Apa yang harus dilakukan jika pembelian tidak berhasil?', jawaban: 'Periksa metode pembayaran Anda dan pastikan saldo atau limit kredit mencukupi. Jika masih ada masalah, coba gunakan metode pembayaran lain atau hubungi penyedia layanan pembayaran Anda.'},
      ],
    }
  },
  components: {
    // HelloWorld
  }
}
</script>

<style scoped>

.bantuan {
  background-color: #524652;
}

</style>
