import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './index.css'
import './assets/styles/font.css';
import 'animate.css';
import './assets/styles/tailwind-snap.css';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/swiper-bundle.css';

createApp(App).use(router).use(VueAwesomeSwiper).mount('#app')
