<template>
  <div class="bg-white h-fit">
    <div class="flex absolute inset-x-0 top-[120px] justify-center xl:inset-x-0 xl:top-48 xl:justify-center 2xl:top-48 items-center">
      <div style="box-shadow: 0.5px 8px grey;" class="font-comic shadow-black-2xl bantuan w-fit rounded-full text-white text-base xl:text-3xl text-center text-xl py-6 px-10 xl:py-10 xl:px-16 leading-relaxed">Syarat & Ketentuan</div>
    </div>
    <div class="text-base text-justify leading-6 xl:px-24 px-5 pt-20 xl:pt-32">
      <div class="text-secondary">Selaku pengguna, Anda diperkenankan memakai fitur-fitur yang tersedia pada situs komik web Visi8 (“Situs”) dengan syarat bahwa Anda menerima segala ketentuan, kondisi, dan pemberitahuan yang diuraikan dan/tersedia di laman ini, serta syarat dan ketentuan tambahan, perjanjian, dan/atau pemberitahuan yang mungkin berlaku untuk setiap laman atau bagian dari Situs.</div>
      
      <ul class="list-decimal">
        <div class="px-5" v-for="i in list" :key="i.index">
          <li class="font-bold mt-5  xl:text-base">{{ i.pertanyaan }}</li>
          <div class="text-secondary">{{ i.jawaban }}</div>
          <ul v-if="i.rincian && i.rincian.length" class="list-disc text-secondary xl:py-5 xl:px-5">
            <li v-for="i in i.rincian" :key="i.index">{{ i }}</li>
            <div>{{ i.lanjutan }}</div>
          </ul>
        </div>
      </ul>

      <div class="text-secondary pt-5 pb-10">
        <div class="text-base">Legal Notice</div>
        <div class="pt-2">Visi8 adalah merek dagang milik PT Visi Delapan Semesta. Hak Cipta © 2023. Seluruh hak dilindungi undang-undang.</div>
      </div>
    </div>
  </div>
</template>

<script>
import siluet from '@/assets/siluet.png'

export default {
  name: 'TermContidion',
  data () {
    return {
      background: siluet,
      list: [
        {pertanyaan: 'Pertanyaan Umum', jawaban: 'Kami akan menerima pertanyaan, komentar, dan masukan Anda tentang privasi atau informasi yang dikumpulkan dari Anda atau tentang Anda. Silakan kirimkan kepada kami semua umpan balik yang berkaitan dengan privasi, atau masalah lainnya.'},
        {pertanyaan: 'Gambaran Umum', jawaban: 'Penggunaan Anda terhadap Situs diartikan sebagai persetujuan Anda terhadap semua ketentuan, kondisi, dan pemberitahuan. Harap membacanya dengan cermat. Dengan menggunakan Situs ini, Anda berarti setuju dengan Syarat dan Ketentuan ini, serta setiap syarat, panduan, atau aturan lain yang berlaku untuk bagian apa pun dari Situs ini, tanpa batasan atau syarat. Jika Anda tidak setuju dengan Syarat dan Ketentuan ini, Anda harus segera keluar dari Situs dan menghentikan penggunaan informasi atau produk dari Situs ini.'},
        {pertanyaan: 'Modifikasi Situs dan Syarat & Ketentuan Ini', jawaban: 'Visi8 berhak untuk mengubah, memodifikasi, mengubah, memperbarui, dan/atau menghentikan syarat, kondisi, dan pemberitahuan di bawah mana Situs ini ditawarkan; serta tautan, konten, informasi, harga, dan materi lain yang ditawarkan melalui Situs ini kapan saja dan dari waktu ke waktu tanpa kewajiban untuk memberitahukan terlebih dahulu dan kewajiban lebih lanjut lainnya terhadap Anda, kecuali jika secara jelas ditentukan sebaliknya di dalamnya. Kami berhak untuk menyesuaikan harga dari waktu ke waktu. Jika terjadi ketidaksesuaian harga, Visi8 berhak menolak pesanan. Dengan melanjutkan penggunaan Anda terhadap Situs setelah modifikasi, perubahan, atau pembaruan tersebut, Anda setuju terikat dengan modifikasi, perubahan, atau pembaruan yang dimaksud.'},
        {pertanyaan: 'Penyerahan Lisensi', jawaban: 'Visi8 memberikan hak kepada Anda untuk mengakses dan menggunakan Platform Perangkat Lunak Berlisensi semata-mata untuk keperluan bisnis internal Anda selama berlakunya Perjanjian ini. Hak tersebut bersifat non-eksklusif, tidak dapat dipindahkan, dan terbatas oleh serta tunduk pada Perjanjian ini. Anda tidak diperkenankan untuk: (a) mengubah, menyesuaikan, mendekompilasi, membongkar, atau melakukan rekayasa balik terhadap komponen apa pun dari Platform Perangkat Lunak Berlisensi; (b) membuat karya turunan berdasarkan komponen apa pun dari Platform Perangkat Lunak Berlisensi; © memperbolehkan pihak ketiga untuk menggunakan atau mengakses komponen apa pun dari Platform Perangkat Lunak Berlisensi atau Dokumentasi.'},
        {pertanyaan: 'Hak Kekayaan Intelektual (HKI)', jawaban: 'Anda mengakui dan menyetujui bahwa: (a) segala HKI dan/atau hak-hak lainnya yang telah atau mungkin timbul atas Platform Webcomic Visi8 adalah milik Visi8 dan/atau pihak yang memberikan lisensi kepada Visi8 dan bukan milik Anda, dan (b) Anda akan menggunakan Platform Webcomic Visi8 hanya berdasarkan syarat dan ketentuan yang dijelaskan di laman ini.'},
        {pertanyaan: 'Biaya', jawaban: 'Sebagai persyaratan atas lisensi yang diberikan, Anda selaku Pengguna atau Penerima Lisensi (“Pengguna/Penerima Lisensi”)harus membayar kepada Visi8 Biaya Lisensi (biaya “License Fee”) sebagaimana tercantum dalam jadwal biaya di halaman yang memuat ketentuan mengenai Pricing (Harga) dalam Situs ini. Biaya Lisensi tidak termasuk PPN dan akan ditagihkan secara bulanan dan dibebankan ke rincian kartu kredit Pengguna/Lisensi. Semua faktur tersebut akan dikirim ke alamat email Pengguna/Penerima Lisensi sebagaimana yang telah Anda tentukan sebagai bagian dari proses pendaftaran di awal. Anda berkewajiban menanggung segala pajak penjualan, pajak penggunaan dan pajak ekspor, serta semua pengenaan nila lain yang berjenis pajak namun ditentukan atas Platform Perangkat Lunak Berlisensi atau lisensinya atau penggunaannya berdasarkan Perjanjian ini, kecuali jika Anda dapat menunjukkan dan menyerahkan kepada Visi8 bukti yang sah bahwa Anda telah dibebaskan dari kewajiban perpajakan sebagaimana dimaksud dalam bentuk yang dapat diterima oleh Visi8.'},
        {pertanyaan: 'Eligibilitas', jawaban: 'Syarat dan Ketentuan ini mencakup penggunaan untuk: i) mengevaluasi Platform Perangkat Lunak Berlisensi, termasuk melalui prototipe yang tersedia pada Preview; dan/atau ii) penggunaan oleh pengembang independen yang lebih kecil, mahasiswa, staf akademik, atau penggemar. Penggunaan lainnya tunduk pada perjanjian komersial terpisah dengan Visi8.'},
        {pertanyaan: 'Ketentuan Penyelesaian', jawaban: 'Visi8, secara sepihak dan/atau atas keputusannya sendiri, dapat menangguhkan atau mengakhiri Perjanjian ini dengan segera jika:', rincian: ['Visi8 mencurigai bahwa Anda membahayakan Platform Perangkat Lunak Berlisensi; atau', 'Anda diduga kuat melakukan pelanggaran yang bersifat materiil terhadap kewajiban Anda dalam Perjanjian ini; atau', 'Anda menghentikan kegiatan usaha atau tidak mampu memenuhi pembayaran terhutang Anda; atau', 'Anda tidak mampu atau mungkin tidak dapat melaksanakan kewajiban Anda dalam Perjanjian ini.'], lanjutan: 'Dalam hal Perjanjian ini diakhiri sebagaimana dimaksud dalam Bagian ini, Anda setuju untuk mengembalikan atau mengonfirmasi penghancuran semua salinan Platform Perangkat Lunak Berlisensi (termasuk SDK) dan Dokumentasi, serta segera menyelesaikan secara penuh segala kewajiban pembayaran Anda berdasarkan Perjanjian ini yang masih terhutang…'},
        {pertanyaan: 'Kerahasiaan', jawaban: 'Anda diperkenankan untuk mengungkapkan Informasi Rahasia hanya kepada direktur, karyawan, dan/atau anggota kelompok Anda yang memerlukan Informasi Rahasia dimaksud semata-mata untuk tujuan Perjanjian; dan/atau kepada penasihat profesional dengan siapa Anda telah mengikatkan diri dalam ketentuan kerahasiaan yang sesuai dan setara dengan kewajiban kerahasiaan Anda berdasarkan Perjanjian ini.'},
        {pertanyaan: 'Jaminan dan Tanggung Jawab Visi8', jawaban: 'Visi8 memberikan jaminan dan berkomitmen penuh kepada Anda bahwa:', rincian: ['Visi8 akan berupaya sebaik-baiknya dan dengan keahilan terbaik yang dimilikinya untuk terus menyediakan Layanan sesuai dengan ketentuan dalam Perjanjian ini; dan', 'Visi8 secara hukum memiliki hak dan wewenang penuh untuk menyediakan Layanan kepada Anda sesuai dengan ketentuan dalam Perjanjian ini.']},
        {pertanyaan: 'Keamanan', jawaban: 'Visi8 akan mengambil semua langkah yang wajar untuk mencegah pelanggaran keamanan dalam interaksi servernya dengan Anda dan pelanggaran keamanan dalam interaksi dengan sumber daya atau pengguna di luar firewall yang mungkin terintegrasi ke dalam server Visi8.'},
        {pertanyaan: 'Kebijakan Privasi', jawaban: 'Informasi Anda aman bersama kami. Visi8 memahami bahwa keamanan privasi dan data pribadi sangat penting bagi pelanggan kami. Kami menjamin dan berkomitmen penuh untuk memastikan bahwa informasi yang Anda berikan kepada kami tidak akan disalahgunakan, atau dijual kepada pihak lain. Kami hanya menggunakan informasi pribadi Anda untuk menyelesaikan pesanan Anda.'},
        {pertanyaan: 'Hukum Yang Berlaku', jawaban: 'Syarat dan Ketentuan ini diatur oleh hukum yang berlaku di Indonesia.'},
    ]}
  },
  components: {
    // HelloWorld
  }
}
</script>

<style scoped>

.bantuan {
  background-color: #524652;
}

</style>
