<template>
  <nav-bar v-if="showComponentNavBar"/>
  <div>
    <img v-if="showComponentNavBarBack" class="w-full h-40 xl:h-64 object-cover xl:object-cover" :src="background" />
    <router-view class="bg-black" v-slot="{ Component, route }">
      <transition
        class="animate__faster"
        :enter-active-class="route.meta.enterClass"
        :leave-active-class="route.meta.leaveClass"
        name="fade"
        mode="out-in"
      >
        <component :is="Component" />
      </transition>
    </router-view>
    <!-- <router-view /> -->
  </div>
  <foo-ter v-if="showComponentNavBarBack"/>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
// import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { watch } from 'vue';
import FooTer from '@/components/FooTer.vue';
import siluet from '@/assets/siluet.png'

export default {
  components: {
    NavBar,
    FooTer
  },
  data () {
    return {
      background: siluet
    }
  },
  computed: {
    showComponentNavBarBack() {
      // Define the routes where you want to show the navbar
      const routesWithNavbarBackground = ['Help and Support', 'Term and Condition', 'Delete Account', 'Privacy and Policy'];
      return routesWithNavbarBackground.includes(this.$route.name);
    },
    showComponentNavBar() {
      // Define the routes where you want to show the navbar
      const routesWithNavbar = ['Help and Support', 'Term and Condition', 'Delete Account', 'Privacy and Policy'];
      return routesWithNavbar.includes(this.$route.name);
    },
  },
  setup() {
    const route = useRoute();

    watch(route, (newRoute) => {
      if (newRoute.meta.backgroundColor) {
        document.documentElement.style.backgroundColor = newRoute.meta.backgroundColor;
      } else {
        // Set a default background color if not specified in the route meta
        document.documentElement.style.backgroundColor = '#000000';
      }
    }, { immediate: true });
  },
}
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s ease-in;
}

</style>
