import { createRouter, createWebHistory } from 'vue-router'
import HelpSupport from '../views/HelpSupport.vue'
import ComingSoon from '../views/ComingSoon.vue'
import TermCondition from '../views/TermCondition.vue'
import DeleteAccount from '../views/DeleteAccount.vue'
import AboutUs from '../views/AboutUs.vue'
import DashBoard from '../views/DashBoard.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'


const routes = [
  {
    path: '/',
    name: 'Coming Soon',
    component: ComingSoon,
    meta: {
      enterClass: 'animate__animated animate__fadeIn',
    }
  },
  {
    path: '/help-support',
    name: 'Help and Support',
    component: HelpSupport
  },
  {
    path: '/term-condition',
    name: 'Term and Condition',
    component: TermCondition
  },
  {
    path: '/delete-account',
    name: 'Delete Account',
    component: DeleteAccount
  },
  {
    path: '/about-us',
    name: 'About Us',
    component: AboutUs,
    meta: {
      enterClass: 'animate__animated animate__fadeIn',
    }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: DashBoard,
  },
  {
    path: '/privacy-policy',
    name: 'Privacy and Policy',
    component: PrivacyPolicy,
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
